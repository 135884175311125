import React, { useState, useEffect } from "react";

import s from "./HistoryPage.module.scss";

import WalletHelper from "../../../helpers/WalletHelper";
import Header from "../../../components/Header/Header";
import TabsWidget from "../../../components/TabsWidget/TabsWidget";
import GivenTokensTable from "./table/GivenTokensTable";

const SHOW_ALL = true;

export default function GivingPage({ user }) {
	const [proofs, setProofs] = useState([]);

	useEffect(() => {
		if (user?.metamaskAccount) {
			const wallet = SHOW_ALL ? null : user?.metamaskAccount;
			WalletHelper.readHistory(wallet).then((data) => {
				setProofs(data);
			});
		}
	}, [user?.metamaskAccount]);

	return (
		<div className={s.root}>
			<Header user={user} showHome={true} />

			<div className={s.content}>
				<TabsWidget
					className={s.tabs}
					tabs={[
						{
							title: `Giving`,
							url: `/admin/history`,
							isActive: true,
						},
						{
							title: `Proofs`,
							url: `/admin/history/proofs`,
						},
					]}
				/>
				<GivenTokensTable data={proofs} />
			</div>
		</div>
	);
}
