import React from "react";

import s from "./AvatarWidget.module.scss";

import AvatarImg from "../../assets/images/avatar.svg";
import CopyTextWidget from "../CopyTextWidget/CopyTextWidget";

export default function AvatarWidget({ user }) {
	return (
		<div className={s.root}>
			{user ? (
				<div className={s.avatarContainer}>
					<div className={s.avatar}>
						<img src={AvatarImg} alt="..." />
					</div>

					<div className={s.keyContainer}>
						<CopyTextWidget
							className={s.accountKey}
							rootClassName={s.copyContainer}
							text={user?.name || user?.metamaskAccount || "no name"}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
}
