import React from "react";

import s from "./HistoryPage.module.scss";

import Header from "../../../components/Header/Header";
import TabsWidget from "../../../components/TabsWidget/TabsWidget";
import ProofTokensTable from "./table/ProofTokensTable";

export default function ProofsPage({ user }) {
	return (
		<div className={s.root}>
			<Header user={user} showHome />

			<div className={s.content}>
				<TabsWidget
					className={s.tabs}
					tabs={[
						{
							title: `Giving`,
							url: `/admin/history`,
						},
						{
							title: `Proofs`,
							url: `/admin/history/proofs`,
							isActive: true,
						},
					]}
				/>

				<ProofTokensTable />
			</div>
		</div>
	);
}
