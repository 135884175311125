import React, { useEffect, useState } from "react";
import cx from "classnames";
import moment from "moment";
import { BootstrapTable } from "react-bootstrap-table";

import { useDebounce } from "../../../../helpers/useDebounce";
import {
	formatAmount,
	getSearchPattern,
	DATETIME_FORMAT,
} from "../../../../helpers/Utils";

import s from "./GivenTokensTable.module.scss";
import ts from "../../../../components/tables/Table.module.scss";

import AppConfig from "../../../../AppConfig";
import TableCell from "../../../../components/tables/table-cell/TableCell";
import TableHeaderCol from "../../../../components/tables/table-column/TableHeaderCol";
import SectionTitleWidget from "../../../../components/SectionTitleWidget/SectionTitleWidget";

export default function GivenTokensTable({ data = [] }) {
	const [tableData, setTableData] = useState(data);
	const [searchText] = useState("");

	const debouncedSearchTerm = useDebounce(searchText, 1000);

	useEffect(() => {
		if (data) {
			const tx = [];
			for (let i = 0; i < data.length; i++) {
				if (data[i]?.private_data) {
					try {
						const pd = JSON.parse(data[i]?.private_data);
						const keys = Object.keys(pd);
						//console.log(keys)
						for (let j = 0; j < keys.length; j++) {
							if (keys[j] !== "tier") {
								const obj = pd[keys[j]];
								obj.tx = keys[j];
								tx[keys[j]] = obj;
							}
						}
					} catch (e) {
						console.log(e);
					}
				}
			}

			const keys = Object.keys(tx).sort((a, b) =>
				tx[a].blockNum !== tx[b].blockNum
					? tx[a].ts - tx[b].ts
					: tx[a].blockNum - tx[b].blockNum
			);
			const res = [];
			keys.forEach((i) => res.push(tx[i]));
			//console.log(res);
			setTableData(res);
		}
	}, [data]);

	useEffect(() => {
		search(searchText);
		// eslint-disable-next-line
	}, [debouncedSearchTerm]);

	// ------------------------------------- METHODS -------------------------------------
	const search = (term) => {
		let filteredData = data || [];
		if (term) {
			const pattern = getSearchPattern(term);

			filteredData = filteredData.filter(
				(a) =>
					a.token?.match(pattern) ||
					a.from?.match(pattern) ||
					a.timestamp?.match(pattern)
			);
		}

		setTableData(filteredData);
	};

	// ------------------------------------- FORMATS -------------------------------------
	const tokenFormatter = (cell) => {
		return (
			<TableCell type="name" text={cell} searchText={searchText} hasCopyIcon />
		);
	};

	const transactionFormatter = (cell) => {
		const idx = cell.toString().indexOf(":");
		return (
			<TableCell
				type="rightAlign"
				text={idx > 0 ? cell.substring(0, idx) : cell}
				searchText={searchText}
				hasCopyIcon
			/>
		);
	};

	const amountFormatter = (cell) => {
		return (
			<TableCell
				type="rightAlign"
				text={formatAmount(cell, 10)}
				className={s.gray}
				hasCopyIcon
			/>
		);
	};

	const usdAmountFormatter = (cell) => {
		return (
			<TableCell
				type="rightAlign"
				text={formatAmount(cell)}
				className={s.gray}
				hasCopyIcon
			/>
		);
	};

	const dateFormatter = (cell, row) => {
		const dt = moment(cell).format(DATETIME_FORMAT);

		return (
			<TableCell
				type="date"
				text={dt}
				searchText={searchText}
				status={row.status}
				hasCopyIcon
			/>
		);
	};

	return (
		<div className={s.root}>
			<div className={ts.root}>
				<BootstrapTable
					data={tableData}
					className={cx(ts.mainTable, "wide-table")}
					trClassName={ts.hover}
					version="4"
					search
					hover
					multiColumnSearch
					pagination={tableData?.length > AppConfig.PAGE_SIZE}
					options={{
						defaultSortName: "ts",
						defaultSortOrder: "desc",
						sizePerPage: AppConfig.PAGE_SIZE,
						hideSizePerPage: true,
						toolBar: () => (
							<div className={s.header}>
								<SectionTitleWidget title="Giving History" />
								{/*
								<TableSearch
									fullWidth={false}
									searchText={searchText}
									setSearchText={setSearchText}
									search={search}
								/>
								*/}
							</div>
						),
					}}
				>
					<TableHeaderCol
						dataField="block"
						title="BlockNum"
						dataFormat={transactionFormatter}
						className={ts.rightAlignTh}
						dataSort
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="ts"
						title="Date"
						dataFormat={dateFormatter}
						className={ts.rightAlignTh}
						dataSort
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="from"
						title={"From"}
						dataFormat={tokenFormatter}
						dataSort
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="token"
						title={"Token"}
						dataFormat={tokenFormatter}
						dataSort
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="value"
						title={"Amount"}
						dataFormat={amountFormatter}
						dataSort
						className={ts.rightAlignTh}
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="usd_value"
						title={"Amount USD"}
						dataFormat={usdAmountFormatter}
						dataSort
						className={ts.rightAlignTh}
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						isKey
						dataField="tx"
						title={"Transaction ID"}
						dataFormat={transactionFormatter}
						className={ts.rightAlignTh}
						dataSort
						width="360"
						columnClassName={ts.highlight}
					/>
				</BootstrapTable>
			</div>
		</div>
	);
}
