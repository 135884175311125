import React from "react";

export default function FoxIcon({ width = 80, height = 80 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M61.6308 18L42.8926 31.9171L46.3577 23.7062L61.6308 18Z"
				fill="#E2761B"
				stroke="#E2761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.375 18L36.9626 32.049L33.6669 23.7062L18.375 18Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M54.889 50.2598L49.8984 57.9057L60.5764 60.8436L63.6461 50.4293L54.889 50.2598Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.3965 50.4293L19.4473 60.8436L30.1253 57.9057L25.1347 50.2598L16.3965 50.4293Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.5224 37.3418L26.5469 41.8427L37.1495 42.3135L36.7729 30.9199L29.5224 37.3418Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M50.484 37.3408L43.1394 30.7871L42.8945 42.3125L53.4783 41.8417L50.484 37.3408Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.125 57.905L36.4903 54.7977L30.9913 50.5039L30.125 57.905Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.5156 54.7977L49.8998 57.905L49.0147 50.5039L43.5156 54.7977Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M49.8979 57.9062L43.5137 54.7988L44.0221 58.9608L43.9657 60.7122L49.8979 57.9062Z"
				fill="#D7C1B3"
				stroke="#D7C1B3"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.125 57.9062L36.0572 60.7122L36.0195 58.9608L36.4903 54.7988L30.125 57.9062Z"
				fill="#D7C1B3"
				stroke="#D7C1B3"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M36.1506 47.7554L30.8398 46.1923L34.5875 44.4785L36.1506 47.7554Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.8535 47.7554L45.4166 44.4785L49.1831 46.1923L43.8535 47.7554Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.1253 57.9057L31.0293 50.2598L25.1348 50.4293L30.1253 57.9057Z"
				fill="#CD6116"
				stroke="#CD6116"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M48.9941 50.2598L49.8981 57.9057L54.8887 50.4293L48.9941 50.2598Z"
				fill="#CD6116"
				stroke="#CD6116"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M53.4764 41.8418L42.8926 42.3126L43.8719 47.7552L45.435 44.4783L49.2014 46.1921L53.4764 41.8418Z"
				fill="#CD6116"
				stroke="#CD6116"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.8407 46.1921L34.6071 44.4783L36.1514 47.7552L37.1495 42.3126L26.5469 41.8418L30.8407 46.1921Z"
				fill="#CD6116"
				stroke="#CD6116"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.5469 41.8418L30.9913 50.5047L30.8407 46.1921L26.5469 41.8418Z"
				fill="#E4751F"
				stroke="#E4751F"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M49.202 46.1921L49.0137 50.5047L53.4769 41.8418L49.202 46.1921Z"
				fill="#E4751F"
				stroke="#E4751F"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M37.1485 42.3125L36.1504 47.7551L37.3933 54.1769L37.6758 45.7212L37.1485 42.3125Z"
				fill="#E4751F"
				stroke="#E4751F"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.8932 42.3125L42.3848 45.7023L42.6108 54.1769L43.8725 47.7551L42.8932 42.3125Z"
				fill="#E4751F"
				stroke="#E4751F"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.8711 47.7545L42.6094 54.1763L43.5133 54.7978L49.0124 50.504L49.2007 46.1914L43.8711 47.7545Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.8398 46.1914L30.9905 50.504L36.4896 54.7978L37.3935 54.1763L36.1506 47.7545L30.8398 46.1914Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.9668 60.7123L44.0233 58.9609L43.5525 58.5466H36.4527L36.0195 58.9609L36.0572 60.7123L30.125 57.9062L32.1966 59.6012L36.3962 62.5202H43.609L47.8274 59.6012L49.899 57.9062L43.9668 60.7123Z"
				fill="#C0AD9E"
				stroke="#C0AD9E"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.5148 54.7992L42.6109 54.1777H37.3943L36.4903 54.7992L36.0195 58.9612L36.4527 58.5468H43.5525L44.0233 58.9612L43.5148 54.7992Z"
				fill="#161616"
				stroke="#161616"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.4214 32.8211L64.0221 25.1375L61.6304 18L43.5137 31.4463L50.4817 37.3409L60.331 40.2222L62.5156 37.6798L61.5739 37.0019L63.0805 35.6271L61.9129 34.7232L63.4195 33.5744L62.4214 32.8211Z"
				fill="#763D16"
				stroke="#763D16"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 25.1375L17.6008 32.8211L16.5838 33.5744L18.0904 34.7232L16.9416 35.6271L18.4482 37.0019L17.5066 37.6798L19.6723 40.2222L29.5217 37.3409L36.4896 31.4463L18.3729 18L16 25.1375Z"
				fill="#763D16"
				stroke="#763D16"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M60.3319 40.2232L50.4826 37.3418L53.4769 41.8427L49.0137 50.5056L54.8894 50.4303H63.6464L60.3319 40.2232Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.5227 37.3418L19.6733 40.2232L16.3965 50.4303H25.1347L30.9916 50.5056L26.5471 41.8427L29.5227 37.3418Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.8919 42.3134L43.5134 31.4471L46.3759 23.707H33.6641L36.4889 31.4471L37.1481 42.3134L37.374 45.7409L37.3929 54.1778H42.6095L42.6471 45.7409L42.8919 42.3134Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeWidth="0.190207"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
