import React from "react";

import { Switch, Route, useRouteMatch } from "react-router";

import GivingPage from "./GivingPage";
import ProofsPage from "./ProofsPage";

export default function AdminConsolePage({ user }) {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				exact
				path={`${path}`}
				render={() => <GivingPage user={user} />}
			/>

			<Route
				exact
				path={`${path}/proofs`}
				render={() => <ProofsPage user={user} />}
			/>
		</Switch>
	);
}
