import React from "react";

import s from "./ImportNFTCard.module.scss";
import CopyWidget from "../CopyWidget/CopyWidget";

export default function ImportNFTCard({ data = {} }) {
	return (
		<div className={s.root}>
			<div className={s.sectionTitle}>Import your NFT to the Metamask App!</div>

			<p className={s.text}>
				1. Open the Metamask app & go to Wallet, then NFTs tab
			</p>
			<p className={s.text}>2. Click "Import NFTs" & input the details below</p>

			<CopyWidget
				text={data.address}
				title="Address"
				rootClassName={s.copyWidget}
			/>
			<CopyWidget text={data.id} title="ID" />
		</div>
	);
}
