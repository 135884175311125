import React, { useRef, useState } from "react";
import { Tooltip } from "reactstrap";
import { useHistory } from "react-router";
import cx from "classnames";

import Highlighted from "../Highlighted/Highlighted";

import s from "./TableCell.module.scss";
import CopyIcon from "../../icons/CopyIcon";
import CopyDoneIcon from "../../icons/CopyDoneIcon";

export default function TableCell({
	popoverId,
	text,
	popoverText,
	type = "name",
	url,
	searchText,
	copyText = null,
	hasCopyIcon = false,
	disableCopy = false,
	showIconTooltip = false, // show tooltip only for icon hover
	textStyle = "",
	className = "",
	onRowClick = () => {},
}) {
	const history = useHistory();
	const [isCopied, setIsCopied] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [isCopyIconVisible, setIsCopyIconVisible] = useState(false);

	const popoverIdRef = useRef(popoverId);

	// ------------------------------------- METHODS -------------------------------------
	const onClick = () => {
		if (disableCopy) return;

		if (url) {
			history.push(url);
		} else {
			copyToClipboard();
		}
	};

	const copyToClipboard = async () => {
		setIsCopied(true);
		setTooltipOpen(false);

		const textToCopy = copyText || text;

		if ("clipboard" in navigator) {
			return navigator.clipboard.writeText(textToCopy);
		} else {
			return document.execCommand("copy", true, textToCopy);
		}
	};

	const onMouseEnter = () => {
		//setIsCopied(false);
		if (hasCopyIcon) setIsCopyIconVisible(true);

		// Show tooltip only when hovering over the clipboard icon
		if (!showIconTooltip) setTooltipOpen(true);
	};

	const onMouseLeave = () => {
		setIsCopyIconVisible(false);
		setTooltipOpen(false);
		setIsCopied(false);
	};

	const openTooltip = () => {
		if (showIconTooltip) {
			setTooltipOpen(true);
		}
	};

	const closeTooltip = () => {
		if (showIconTooltip) {
			setTooltipOpen(false);
		}
	};

	return (
		<div
			className={cx(s.root, className, {
				[s.rightCell]: type === "rightAlign",
			})}
			onClick={onRowClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div
				ref={!showIconTooltip ? popoverIdRef : null}
				className={s.highlighter}
				onClick={onClick}
			>
				{searchText ? (
					<Highlighted
						className={textStyle}
						highlight={searchText}
						text={text}
					/>
				) : (
					<div className={textStyle}>{text}</div>
				)}
			</div>

			{isCopyIconVisible && (
				<div
					className={s.icon}
					ref={showIconTooltip ? popoverIdRef : null}
					onClick={copyToClipboard}
					onMouseEnter={openTooltip}
					onMouseLeave={closeTooltip}
				>
					{isCopied ? (
						<CopyDoneIcon width="16" height="16" color={"var(--app-color)"} />
					) : (
						<CopyIcon width="16" height="16" color={"var(--app-color)"} />
					)}
				</div>
			)}

			{popoverText && tooltipOpen ? (
				<Tooltip placement="top" target={popoverIdRef} isOpen={true}>
					<p className="tooltip-text">{isCopied ? "Copied" : popoverText}</p>
				</Tooltip>
			) : null}
		</div>
	);
}
