import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import s from "./TableHeaderCol.module.scss";

import TooltipWidget from "../../TooltipWidget/TooltipWidget";

const LETTER_WIDTH = 8;

export default function TableHeaderCol(props) {
	const { title, popoverText, titleInfo, width } = props;

	const tooltipText = popoverText
		? popoverText
		: width && title?.length * LETTER_WIDTH >= width && title !== "Last Updated"
		? title
		: "";

	return (
		<TableHeaderColumn {...props}>
			<div className={s.root}>
				<TooltipWidget placement="top" tooltipText={tooltipText}>
					{title}
					{titleInfo && <span>{titleInfo}</span>}
				</TooltipWidget>
			</div>
		</TableHeaderColumn>
	);
}
