import React from "react";

import s from "./NFTCard.module.scss";
import AppConfig from "../../AppConfig";

const USE_JPG = true;

export default function NFTCard({ data = {} }) {
	const img = USE_JPG ? data.image.replace(".png", ".jpg") : data.image;

	return (
		<div className={s.root}>
			<a
				href={AppConfig.OPENSEA_COLLECTION_URL + "/" + data.id}
				target="_blank"
				rel="noreferrer"
			>
				<p className={s.title}>{data.name}</p>
				<img src={img} alt="{data.description" />
			</a>
			<p className={s.desc}>{data.description}</p>
		</div>
	);
}
