import React from "react";
import s from "./ErrorPage.module.scss";

import Header from "../../../components/Header/Header";
import AppImages from "../../../AppImages";

export default function ErrorPage({ user }) {
	return (
		<div className={s.root}>
			<Header user={user} />

			<div className={s.content}>
				<div className={s.title}>Sorry!</div>
				<div className={s.description}>
					This wallet isn't whitelisted to mint NFTs from this page.
				</div>

				<div className={s.description}>
					You need to logout and connect with a whitelisted wallet to use this
					tool.
				</div>

				<div className={s.image}>
					<img src={AppImages.AccessDenied} alt="..." />
				</div>
			</div>
		</div>
	);
}
