import React, { useState, useEffect } from "react";
import { FormGroup, Input, Tooltip } from "reactstrap";

import cx from "classnames";
import { formatPublicKey } from "../../helpers/Utils";

import s from "./CopyWidget.module.scss";

import AppConfig from "../../AppConfig";
import CopyDoneIcon from "../icons/CopyDoneIcon";
import CopyIcon from "../icons/CopyIcon";

const CopyWidget = ({
	text,
	placeholder,
	disabled = false,
	showCopiedConfirmation = false,
	className = "",
	rootClassName = "",
	subText = "",
	title,
}) => {
	const [value, setValue] = useState(text);
	const [isCopied, setIsCopied] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const placeholderText =
		placeholder || "ex. " + formatPublicKey(AppConfig.samplePublicKey);

	useEffect(() => {
		setValue(text);
	}, [text]);

	const copyToClipboard = async () => {
		if (!value) return;

		setIsCopied(true);
		setTooltipOpen(false);

		setTimeout(() => {
			setTooltipOpen(true);
		}, 10);

		if ("clipboard" in navigator) {
			return navigator.clipboard.writeText(value);
		} else {
			return document.execCommand("copy", true, value);
		}
	};

	const closeTooltip = () => {
		setTooltipOpen(false);
	};

	const CopyIconTag = isCopied ? CopyDoneIcon : CopyIcon;

	return (
		<div className={cx(s.root, rootClassName)}>
			{title && <div className={s.title}>{title}</div>}
			
			<FormGroup className={s.container}>
				<Input
					className={cx(
						s.copyInput,
						className,
						value ? "" : s.placeholder,
						isCopied ? s.valid : ""
					)}
					placeholder={placeholderText}
					value={value || ""}
					onChange={() => {}}
					disabled={disabled}
					onClick={copyToClipboard}
				/>
				{subText && <p className={s.inputSubText}>{subText}</p>}
				{isCopied && showCopiedConfirmation && (
					<p className={cx(s.inputSubText, s.copied)}>Copied!</p>
				)}

				<div className={s.icons}>
					<div
						className={cx(s.iconContainer)}
						id="tooltipTarget"
						onMouseEnter={() => setTooltipOpen(true)}
						onMouseLeave={closeTooltip}
						onClick={copyToClipboard}
					>
						<CopyIconTag />
					</div>

					{tooltipOpen ? (
						<Tooltip placement="bottom" target="tooltipTarget" isOpen={true}>
							<p className="tooltip-text">
								{isCopied ? "Copied" : "Copy to clipboard"}
							</p>
						</Tooltip>
					) : null}
				</div>
			</FormGroup>
		</div>
	);
};
export default CopyWidget;
