import React, { useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import s from "./MintButton.module.scss";

import AppMessages from "../../AppMessages";
import BlockchainHelper from "../../helpers/BlockchainHelper";
import InsufficientFundsDialog from "../../components/metamask/InsufficientFundsDialog/InsufficientFundsDialog";
import MetamaskErrorDialog from "../../components/metamask/MetamaskErrorDialog/MetamaskErrorDialog";
import MetamaskSteps from "../metamask/MetamaskStepsDialog/MetamaskSteps";
import MetamaskStepsDialog from "../../components/metamask/MetamaskStepsDialog/MetamaskStepsDialog";
import WalletHelper from "../../helpers/WalletHelper";
import { toast } from "react-toastify";

const useTestnet = false;

export default function MintButton({
	text = AppMessages.MINT_BUTTON_TEXT,
	receiverAddress,
	onValidate = () => {}, // after address check
	//selfMint = true,
}) {
	const history = useHistory();
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [hasError, setHasError] = useState(false);
	const [metamaskStep, setMetamaskStep] = useState(MetamaskSteps.ownership);

	const { ethereum } = window;

	const chain = BlockchainHelper.getChain(useTestnet);

	// ------------------------------------- METHODS -------------------------------------
	const switchNetwork = async () => {
		try {
			if (1 * ethereum.networkVersion === chain.chainId) {
				return;
			}
			return await ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: chain.chainId }],
			});
		} catch (switchError) {
			const msg = switchError?.message || "";
			if (!msg.includes("already pending") && !msg.includes("User rejected")) {
				try {
					if (1 * ethereum.networkVersion === chain.chainId) {
						return;
					}

					return await ethereum.request({
						method: "wallet_addEthereumChain",
						params: [chain],
					});
				} catch (error) {
					console.debug(error);
					setHasError(true);
				}
			}

			// If we got to this this point something went wrong
			return true;
		}
	};

	const executeMint = (wallet, receiverAddress) => {
		BlockchainHelper.switchChain(false).then(() =>
			WalletHelper.mint(wallet, receiverAddress).then((res) => {
				if (res) {
					history.push("/nft");
					WalletHelper.getBalanceOf(receiverAddress).then((res) => {
						toast.info("Account balance: " + res);
					});
				} else {
					setShowConfirmation(false);
					setHasError(true);
				}
			})
		);
	};

	/* 	const checkUserWallet = async (wallet) => {
		const userHasNFT = await WalletHelper.checkIfUserHasNFT(wallet);
		if (userHasNFT) {
			setShowConfirmation(false);
			onValidate(AppMessages.AdminMintValidationError);
		}

		return userHasNFT;
	}; */

	const onMint = async () => {
		setShowConfirmation(true);

		try {
			setErrorMessage(null);

			if (!ethereum) {
				setShowConfirmation(false);
				return;
			}

			// GET ACCOUNT
			setMetamaskStep(MetamaskSteps.ownership);

			const accounts = await ethereum.request({
				method: "eth_requestAccounts",
			});

			const account = accounts[0];
			const productChainId = BlockchainHelper.getChainId(useTestnet);

			if (1 * ethereum.networkVersion !== productChainId) {
				setMetamaskStep(MetamaskSteps.network);
				const switchError = await switchNetwork();
				if (switchError) {
					return setHasError(true);
				}
			}

			setMetamaskStep(MetamaskSteps.payment);

			setShowInsufficientFunds(false);
			setMetamaskStep(MetamaskSteps.processing);

			executeMint(account, receiverAddress || account);
		} catch (error) {
			console.log(error);
			setHasError(true);
		}
	};

	const onErrorCancel = () => {
		setHasError(false);
		setShowConfirmation(false);
	};

	const onErrorBack = () => {
		setHasError(false);
		setShowConfirmation(false);
		setShowInsufficientFunds(false);
	};

	return (
		<div className={s.root}>
			{showConfirmation && !hasError ? (
				<MetamaskStepsDialog
					step={metamaskStep}
					network={chain}
					close={() => setShowConfirmation(false)}
				/>
			) : null}

			{showInsufficientFunds && (
				<InsufficientFundsDialog
					close={() => setShowInsufficientFunds(false)}
					onContinue={onErrorBack}
				/>
			)}

			{hasError && (
				<MetamaskErrorDialog
					close={onErrorCancel}
					onContinue={onErrorBack}
					message={errorMessage}
				/>
			)}

			<Button className={s.button} color="primary" onClick={onMint}>
				{text}
			</Button>
		</div>
	);
}
