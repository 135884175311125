import React, { useEffect, useState } from "react";
import cx from "classnames";
import moment from "moment";
import { BootstrapTable } from "react-bootstrap-table";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

import { DATETIME_FORMAT } from "../../../../helpers/Utils";

import s from "./GivenTokensTable.module.scss";
import ts from "../../../../components/tables/Table.module.scss";

import TableCell from "../../../../components/tables/table-cell/TableCell";
import TableHeaderCol from "../../../../components/tables/table-column/TableHeaderCol";
import WalletHelper from "../../../../helpers/WalletHelper";
import SectionTitleWidget from "../../../../components/SectionTitleWidget/SectionTitleWidget";
import SearchingGif from "../../../../components/icons/SearchingGif";

export default function ProofTokensTable() {
	const [tableData, setTableData] = useState([]);
	const [searchText] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [writesOnly, setWritesOnly] = useState(false);

	useEffect(() => {
		getProofsData(writesOnly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [writesOnly]);

	// ------------------------------------- METHODS -------------------------------------
	const getProofsData = (writesOnly) => {
		setIsLoading(true);
		WalletHelper.getComputeProofs(writesOnly).then((data) => {
			setIsLoading(false);
			setTableData(data);
		});
	};

	// ------------------------------------- FORMATS -------------------------------------
	const publicKeyFormatter = (cell) => {
		return (
			<TableCell type="name" text={cell} searchText={searchText} hasCopyIcon />
		);
	};

	const defaultFormatter = (cell) => {
		return <TableCell text={cell} hasCopyIcon searchText={searchText} />;
	};

	const dateFormatter = (cell, row) => {
		const dt = moment(cell).format(DATETIME_FORMAT);

		return (
			<TableCell
				type="date"
				text={dt}
				searchText={searchText}
				status={row.status}
				hasCopyIcon
			/>
		);
	};

	const verifyRowProofs = (row, src) => {
		setIsLoading(true);
		WalletHelper.verifyRowProofs(row.backendRow).then((res) => {
			setIsLoading(false);

			console.log(
				"Integrity signature verification result: " + JSON.stringify(res)
			);
			if (res === false) {
				toast.error("Integrity check failed");
				row.validated = false;
				src.classList.add(s.btnFailure);
				src.blur();
				return;
			} else if (res === true) {
				toast.success("Integrity check successful");
				row.validated = true;
				src.classList.add(s.btnSuccess);
				src.blur();
				return;
			}
		});
	};

	const actionFormatter = (_, row) => {
		return (
			<div>
				<Button
					color="primary"
					className={"btn-border"}
					onClick={(evt) => verifyRowProofs(row, evt.currentTarget)}
				>
					Verify
				</Button>
			</div>
		);
	};

	return (
		<div className={s.root}>
			<div className={ts.root}>
				<BootstrapTable
					data={tableData}
					className={cx(ts.mainTable, "wide-table")}
					trClassName={ts.hover}
					version="4"
					search
					hover
					multiColumnSearch
					pagination={tableData?.length > 10}
					options={{
						defaultSortName: "timestamp",
						defaultSortOrder: "desc",
						sizePerPage: 10,
						hideSizePerPage: true,
						toolBar: () => (
							<div className={s.header}>
								<SectionTitleWidget title="Proofs of Fair Giving Credit" />
								<div
									className={s.selector}
									onClick={() => setWritesOnly(!writesOnly)}
								>
									{isLoading ? <SearchingGif /> : null}
									<input
										type="checkbox"
										checked={!writesOnly}
										onChange={() => {}}
									/>
									<label>Show all proofs </label>
								</div>
							</div>
						),
					}}
				>
					<TableHeaderCol
						isKey
						dataField="id"
						title="ID"
						dataSort
						columnClassName={ts.highlight}
						width="80"
					/>
					<TableHeaderCol
						dataField="timestamp"
						title="Timestamp"
						dataFormat={dateFormatter}
						dataSort
						width="160"
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="publicKey"
						title={"Node"}
						dataFormat={publicKeyFormatter}
						dataSort
						columnClassName={ts.highlight}
						width="300"
					/>
					<TableHeaderCol
						dataField="integrityGuarantee"
						title={"Integrity Guarantee"}
						dataFormat={defaultFormatter}
						dataSort
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataField="data"
						title={"Data"}
						dataSort
						dataFormat={defaultFormatter}
						columnClassName={ts.highlight}
					/>
					<TableHeaderCol
						dataFormat={actionFormatter}
						columnClassName={ts.highlight}
					/>
				</BootstrapTable>
			</div>
		</div>
	);
}
