import React, { useEffect, useState } from "react";

import { formatAmount } from "../../helpers/Utils";

import s from "./OpenSeaCard.module.scss";

import AppConfig from "../../AppConfig";
import AppImages from "../../AppImages";
import WalletHelper from "../../helpers/WalletHelper";
import ImportNFTCard from "../ImportNFTCard/ImportNFTCard";

export default function OpenSeaCard({ user, nfts }) {
	const [totalValue, setTotalValue] = useState(null);

	const url = user?.metamaskAccount
		? AppConfig.OPENSEA_URL + user?.metamaskAccount
		: AppConfig.OPENSEA_COLLECTION_URL;

	useEffect(() => {
		if (nfts && nfts.length > 0) {
			WalletHelper.readNFTData(nfts[0].id).then((res) => {
				if (res?.private_data) {
					try {
						let sum = 0;
						const items = JSON.parse(res.private_data);
						Object.keys(items).forEach((r) => {
							if (items[r].usd_value) sum += items[r].usd_value;
						});
						setTotalValue(sum);
					} catch (e) {
						console.log(e);
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nfts]);

	return (
		<div className={s.root}>
			{nfts && nfts.length > 0 ? (
				<>
					{totalValue ? (
						<>
							<p className={s.header}>Total given</p>
							<p className={s.amount}>${formatAmount(totalValue)}</p>
						</>
					) : null}

					{/* IMPORT NFT INSTRUCTIONS */}
					<ImportNFTCard
						data={{
							address: AppConfig.NFT_TOKEN_URI,
							id: nfts[0].id,
						}}
					/>
				</>
			) : null}

			<p className={s.header}>Troubleshooting OpenSea</p>
			<p className={s.text}>
				Having trouble seeing your updated Dynamic NFT on{" "}
				<a href={url} target="_blank" rel="noreferrer">
					OpenSea?
				</a>
			</p>
			<p className={s.text}>
				1. Select your NFT then click the "..." icon at the top right of the
				screen where your NFT is displayed
			</p>
			<p className={s.text}>
				2. Click "Refresh metadata" to display the most recent version of your
				Dynamic NFT
			</p>

			<img src={AppImages.OpenSeaImg} alt="..." />
		</div>
	);
}
